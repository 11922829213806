import React, { useState, useEffect,useRef } from "react";
import {  Row, Col } from "antd";
import Stack from "@mui/material/Stack";
import moment from "moment";
import imageSencillo from "../../../../images/turbo1.png";
import imageVehicle from  "../../../../images/Vehiculo.png"
import { CarOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { Grid, Divider, TextField, IconButton, InputAdornment, Box, Card } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';
import WhereToVoteRoundedIcon from '@mui/icons-material/WhereToVoteRounded';

const RouteCardLoad = ({ routesBatch, onSelectRoute }) => {

  const [selectedRoute, setSelectedRoute] = useState(null);
  const selectedRef = useRef(null);
  let imageSelected = null;
  let imageName = null
  const handleRouteClick = (route) => {
    setSelectedRoute(route);
    onSelectRoute(route);
    imageName = route.routes[0]?.user?.userVehicle?.vehicle?.vehicleType?.name;

    if (imageName && imageName.length > 0) {
      if (imageName === "SENCILLO") {
        imageSelected = imageSencillo;
      } else if (imageName === "VEHICULO") {
        imageSelected = imageVehicle;
      }
    }
    
  };
  useEffect(() => {

    if (routesBatch?.length > 0 && !selectedRoute) {
        setSelectedRoute(routesBatch[0]);
        onSelectRoute(routesBatch[0]);
        
    }
}, [routesBatch, selectedRoute, onSelectRoute]);


 
  return (
    <div>
      {routesBatch?.length > 0 &&
        routesBatch.map((route, index) => (
          <Box
            key={index}
            className="children-inner-container-target-with-bg-route"
            style={{
              marginBottom: "10px",
              padding: "20px",
              height: "100%",
              border: selectedRoute === route ? "2px solid #D10505" : "2px solid transparent"
            }}
            onClick={() => handleRouteClick(route)}
           
           
          >
          
            <Grid container spacing={1}>
              <Grid item xs={7}>
              <Box className="drive-details-labels" >
               
                    <span className="drive-details-label">Conductor:</span> <br />
                    <span className="drive-details-value">{route.driverName}</span><br />
                    <span className="drive-details-label">Placa: </span>
                    <span className="drive-details-value">{route.licencePlate}</span>
               
              </Box>
              </Grid>
              <Grid item xs={5}>
              <Box className="drive-details-labels">
                
                <img src={route.routes[0]?.user?.userVehicle?.vehicle?.vehicleType?.name == "SENCILLO" ? imageSencillo : imageVehicle} alt="Vehículo" style={{ width: "70%" }} /><br />
                <span className="drive-details-value">{route.routes[0]?.user?.userVehicle?.vehicle?.vehicleType?.name}</span>
             
              </Box>

              </Grid>
            </Grid>
            <Divider variant="middle" style={{marginBottom: "5px", marginTop: "5px"}} />

            <Grid container spacing={1}>
              <Grid item xs={12}>
              <Box className="drive-details-labels">           
             
                <span className="drive-details-label">Fecha de creación:</span> <br />
                <span className="drive-details-value fecha-creacion">
                  {moment(route.startDateRoute).format("DD/MM/YYYY")}
                </span><br />
                <span className="drive-details-label">
                 {moment(route.startDateRoute).format("hh:mm:ss A")}
                </span>
              </Box>
              </Grid>
            </Grid>

            <Divider variant="middle" style={{marginBottom: "5px", marginTop: "5px"}} />

            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Box>
                <br />
                    <span className="drive-details-value">Inicio de ruta</span>
                    <br /> <br /> 
                    <span className="drive-details-value">Fin de ruta</span> 
                    
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box>
                  
                </Box>
                </Grid>
              </Grid>
              
             <Divider variant="middle" style={{marginBottom: "10px"}} />

              <Grid container spacing={1}>   
                
                <Grid item xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar style={{ backgroundColor: "#D10505" }} size="large">
                    {route.planner.charAt(0)}
                  </Avatar>
                  <div style={{ marginLeft: "10px" }}>
                    <span className="drive-details-label">Planificador:</span><br/>
                    <span className="drive-details-value planificator">{route.planner}</span>
                  </div>
                </div>
                </Grid>
               
              </Grid>

            {/*
              <div>
                <p className="drive-details-label-titles">Planificador</p>
                <Row align="middle">
                  <Col span={6} style={{ textAlign: "center" }}>
                    <Avatar style={{ backgroundColor: "#D10505", verticalAlign: "middle" }} size="large">
                      {route.planner.charAt(0)}
                    </Avatar>
                  </Col>
                  <Col span={18}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p className="drive-details-value planificator">
                        {route.planner}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
        */}
          </Box >
        ))}
    </div>
  );
};

export default RouteCardLoad;
