import React, { useEffect, useMemo, useState } from "react";
import "../stylesTMS/styles-tms.css";
import { useTMSStore } from "../../../../../../store/tms-store";
import { getLoadsData } from "./services/get-loads-data";
import { TMSFiltersCarga } from "../screen/components/tms-filter-carga";
import { TMSSearchText } from "../screen/components/tms-filters-search-text";
import { TMSFiltersTransport } from "../screen/components/tms-filter-transport";
import { TMSFiltersOvTrn } from "../screen/components/tms-filter-ovtrn";
import { TMSFiltersDate } from "../screen/components/tms-filter-date";
import DetailCarga from "./components/DetailCarga";
import ShippingList from "./components/ShippingList";
import ActionButtons from "./components/ActionButtons";
import InfoEnvios from "./components/InfoEnvios";
import PaginatedComponent from "./components/PaginatedComponent";
import CircularProgress from "@mui/material/CircularProgress";
import { Table, Col, Pagination, Row, Tag, Card } from "antd";
import {
  ArrowLeftOutlined,
  RedoOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { TextField } from "@fluentui/react/lib/TextField";
import "../stylesTMS/styles.css";
import { useLazyQuery } from "@apollo/client";
import {
  fetchOrders,
  fetchSectors,
  fetchDistributionCenters,
  fetchSearchConsecutive,
} from "../../../screens/TMS-Dynamics/queries/TMSqueries";
import moment from "moment";
import "moment/locale/es";
import { getColumnSearchPropsText } from "../../../../../completed-routes/ui/screens/UseCases";
import localStorageService from "../../../../../../services/localStorageService";
import { ReactComponent as FilterIcon } from "../../../../../../images/filter_alt_off_FILL0_wght400_GRAD0_opsz24.svg";
import MapIcon from "../../../../../../images/mapa.png";
import MapModal from "./components/MapModal";
import { IconButton } from "@mui/material";

const CenterUser = localStorageService.get("selectedWarehousesname");

const scheme = (proto) =>
  window.location.protocol === "https:" ? `${proto}s` : proto;

export const TMSLoadScreenV2 = (props) => {
  const history = useHistory();
  const [loads, setLoads] = useState([]);
  const [dataFilterLoad, setDataFilterLoad] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterTransport, setFilterTransport] = useState("NAVI");
  const [filterTransportOvTrn, setFilterTransportOvTrn] = useState("");
  const [selectedPageSize, setSelectedPageSize] = useState(20);
  const [selectedPageId, setSelectedPageId] = useState(1);
  const [selecteLoad, setSelecteLoad] = useState();
  const [isLoadSelected, setIsLoadSelected] = useState(false);
  const [selecteLoadCarga, setSelecteLoadCarga] = useState(null);
  const [selecteShipping, setSelecteShipping] = useState(null);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [listShipping, setListShipping] = useState([]);
  const [selectedShippingId, setSelectedShippingId] = useState(null);
  const [isDetailExpanded, setIsDetailExpanded] = useState(true);
  const [showDetail, setShowDetail] = useState(false);
  const [isMapSelected, setIsMapSelected] = React.useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [isMapModalOpen, setIsMapModalOpen] = React.useState(false);
  const [selectedShippingsByLoad, setSelectedShippingsByLoad] = useState({});
  const [pageData, setPageData] = useState([]);
  const [count, setCount] = useState();
  const [filterState, setFilterState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchVisibleOvTrn, setSearchVisibleOvTrn] = useState(false);
  const [searchVisibleUm, setSearchVisibleUm] = useState(false);
  const [selectedWarehousename, setSelectedWarehousename] = useState(
    JSON.parse(localStorageService.get("selectedWarehousesname"))
      ?.warehousesname
  );

  const setLoadsData = useTMSStore((state) => state.setLoadsData);
  const warehousesname = useTMSStore((state) => state.warehousesname);
  const searchText = useTMSStore((state) => state.searchText);
  const initLoading = useTMSStore((state) => state.initLoading);
  const startLoading = useTMSStore((state) => state.startLoading);
  const startInitLoading = useTMSStore((state) => state.startInitLoading);
  const seeDispatched = useTMSStore((state) => state.seeDispatched);
  const seeDelivery = useTMSStore((state) => state.seeDelivery);
  const transport = useTMSStore((state) => state.transport);
  const ovTrn = useTMSStore((state) => state.ovTrn);
  const fromDate = useTMSStore((state) => state.fromDate);
  const toDate = useTMSStore((state) => state.toDate);
  const setSearchTextOvTrn = useTMSStore((state) => state.setSearchTextOvTrn);
  const setSearchText = useTMSStore((state) => state.setSearchText);
  const setSearchFromDate = useTMSStore((state) => state.setSearchFromDate);
  const setSearchToDate = useTMSStore((state) => state.setSearchToDate);
  const [searchFilter, setSearchFilter] = useState("");
  const [isBlankActive, setIsBlankActive] = useState(false);
  const [isBlankActiveOvtm, setIsBlankActiveOvtm] = useState(false);
  const [saveConsecutive, setSaveConsecutive] = useState();

  const [getOrderRunning, dataOrderRunning] = useLazyQuery(fetchOrders, {
    fetchPolicy: "no-cache",
  });
  const [getSectors, dataSectors] = useLazyQuery(fetchSectors, {
    fetchPolicy: "no-cache",
  });

  const [getDistributionCenters, dataDistributionCenters] = useLazyQuery(
    fetchDistributionCenters,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [getSearchConsecutive, dataSearchConsecutive] = useLazyQuery(
    fetchSearchConsecutive,
    {
      fetchPolicy: "no-cache",
    }
  );

  const handleSelectLoad = (carga) => {
    setSelecteLoadCarga(carga);
    setShowDetail(true);
    setIsDetailExpanded(true);
    setIsLoadSelected(true);
    setSelecteShipping(null);
    setSelectedShippingId(null);
    setIsMapSelected(false);
  };

  const handleSelectShipping = (shipping) => {
    setSelecteShipping(shipping);
    setSelectedShippingId(shipping.ShipmentId);
    setIsDetailExpanded(false);
    setIsLoadSelected(false);
    setIsMapSelected(false);
    setTabValue(0);
  };

  const handleResetShipping = () => {
    setSelecteShipping(null);
    setSelectedShippingId(null);
    setIsDetailExpanded(true);
    setIsLoadSelected(false);
  };

  const handleNewLocationUpdate = async (newLocation) => {
    // Actualizar la ubicación del envío en la lista de envíos
    const updatedShipments = selecteLoadCarga.LoadLines.map((shipment) => {
      if (shipment.ShipmentId === selecteShipping.ShipmentId) {
        // Reemplazar los campos existentes con los nuevos valores
        return {
          ...shipment,
          City: newLocation.ciudad,
          State: newLocation.departamento,
          Street: newLocation.direccion,
          BuildingCompliment: newLocation.complemento,
        };
      }
      return shipment;
    });

    for (let i = 0; i < listShipping.length; i++) {
      if (listShipping[i].ShipmentId === selecteShipping.ShipmentId) {
        listShipping[i].City = newLocation.ciudad;
        listShipping[i].State = newLocation.departamento;
        listShipping[i].Street = newLocation.direccion;
      }
    }
    // Actualizar el estado con la lista de envíos actualizada
    setSelecteLoadCarga({
      ...selecteLoadCarga,
      LoadLines: updatedShipments,
    });

    // Actualizar el envío seleccionado
    setSelecteShipping({
      ...selecteShipping,
      City: newLocation.ciudad,
      State: newLocation.departamento,
      Street: newLocation.direccion,
      BuildingCompliment: newLocation.complemento,
    });
    setIsLoadSelected(false);
    setIsMapSelected(false);
    setTabValue(0);
  };

  // Llamar a la función para seleccionar el envío después de actualizar su ubicación

  const handleMapSelect = (isSelected) => {
    setIsMapSelected(isSelected);
  };

  const handleOpenMapModal = () => {
    setIsMapModalOpen(true);
  };

  // Función para cerrar el modal
  const handleCloseMapModal = () => {
    setIsMapModalOpen(false);
  };

  const handleClearFilters = () => {
    setSearchText("")
    setSearchTextOvTrn("")
    setSearchFromDate("")
    setSearchToDate("")
  };

  useEffect(() => {
    startInitLoading();
  }, []);

  useEffect(() => {
    const warehousesname = JSON.parse(localStorageService.get("selectedWarehousesname"))?.warehousesname;
    setSelectedWarehousename(warehousesname);
    if(dataOrderRunning.data?.ordersTable?.length >= 0 && dataSectors.data?.sectors?.length >= 0 && dataDistributionCenters.data?.distributionCenters?.length >= 0){
      if(loads?.length !== 0){
        getTMSLoad(dataOrderRunning.data?.ordersTable, warehousesname, selectedPageSize, selectedPageId, filter, filterTransport, filterTransportOvTrn);
      }
    }
}, [props.flag,dataOrderRunning, dataSectors, dataDistributionCenters, selectedPageSize, selectedPageId]);


  useEffect(() => {
    getTMSLoad();
  }, [
    warehousesname,
    fromDate,
    toDate,
    searchText,
    seeDispatched,
    seeDelivery,
    transport,
    ovTrn,
  ]);

  const getTMSLoad = async (Ordertable, Center, pageSize, pageId) => {
    startLoading();
    setLoading(true);
    if (!dataOrderRunning || !dataOrderRunning.data) {
      console.log("dataOrderRunning is not defined yet.");
      return; // Salir si dataOrderRunning no está definido
    }
    const orderConsecutiveShippingList = dataOrderRunning.data?.ordersTable?.filter(order => order.consecutiveShipping).map(order => order.consecutiveShipping);

    const endpoint = process.env.REACT_APP_FUNCTION_GET_LOAD_FULL_DATA;

    const bodyLoad = {
      tenantUrl: "navitrans.com.co",
      clientId: "97a8cc5c-65a7-40ac-b1b8-4c9f50e2bc3b",
      clientSecret: "Iwn8Q~aK8zD83KUsmXUABb8l_eRJ6G~oOzYCgcRB",
      tenant: "https://uat4-navitrans.sandbox.operations.dynamics.com",
      environment: "UAT4",
      loadId: searchText === undefined ? "" : searchText,
      inventLocationId: warehousesname?.length === 0 ? "" : warehousesname,
      pageSize: pageSize === undefined ? selectedPageSize : pageSize,
      pageId: pageId === undefined ? 1 : pageId,
      isDescending: true,
      fromDate: fromDate === undefined ? "" : fromDate,
      toDate: toDate === undefined ? "" : toDate,
      seeDispatched: 0,
      filterByDispatchDate: false,
      transport: transport === undefined ? "" : transport,
      ovTrn: ovTrn === undefined ? "" : ovTrn,
    };

    try {
      await axios.post(endpoint, bodyLoad).then(async (response) => {
        const _data = response.data.response;
        const count = response.data.count;
        let consecutive = _data.map((item) => {
          return {
            LoadId: item.LoadId,
          };
        });

        const consecutiveBurden = consecutive.map((item) => item.LoadId);
        const brudenNumbers = await getSearchConsecutive({
          variables: { consecutiveBurden: consecutiveBurden },
        });

        let loads;
      if (brudenNumbers.data.ordersTable.length > 0) {
        loads = _data.map(item => {
            const foundElement = brudenNumbers.data.ordersTable.find(element => element.consecutiveBurden === item.LoadId);
            return {
                ...item,
                orderNumber: foundElement ? foundElement.orderNumber : ""
            };
        });
       }else{
        loads = _data.map((item)=>{
          return({
            ...item,
            orderNumber: []
          })
        });
      }

        const data = loads
        .map((item) => { 
          let status = "Cargando";
    
          const lineQty = item.LoadLines?.filter((loadLine) => {
            if (orderConsecutiveShippingList?.includes(loadLine.ShipmentId)) {
              return true;
            }
            return false;
          }).length;

          if (lineQty === item.LoadLines?.length) {
            status = "Sincronizado";
          } else if (lineQty > 0) {
            status = "Parcialmente Sincronizado";
          } else {
            status = item.LoadStatus;
          }

          let InvoiceQty = 0;
          let SlipShippingQty = 0;
          let LinesQty = 0;
          let ContainersQty = 0;
    
          let PaymentOnCashValue = 0;
          let PaymentOnCashValueCurrency = "";
          let PaymentMode = "";
    
          for (let i = 0; i < item.LoadLines.length; i++) {
            const line = item.LoadLines[i];
            InvoiceQty+= line.InvoiceList ? line.InvoiceList.length : 0;

            if(InvoiceQty > 0){
              for (let j = 0; j < line.InvoiceList.length; j++) {
                const invoice = line.InvoiceList[j];
                PaymentMode = invoice.Payment;
                if(invoice.Payment === "CONTRAENTREGA"){
                  PaymentOnCashValue+= invoice.InvoiceAmount;
                  PaymentOnCashValueCurrency = invoice.CurrencyCode;
                }
              }
            }
    
            SlipShippingQty += line.PackingSlipList ? line.PackingSlipList.length : 0;
            LinesQty += line.ItemList ? line.ItemList.length : 0;
            ContainersQty += line.ContainerList ? line.ContainerList.length : 0;
          }
    
          return {
            ...item,
            key: item.LoadId,
            Type: item.LoadLines[0]?.Type ?? item.Type,
            LoadStatus: status,
            Order: status === "Enviados" ? 1 : status === "Cargado" ? 2 : status === "Parcialmente Sincronizado" ? 3 : 4,
            InvoiceQty,
            SlipShippingQty,
            LinesQty,
            ContainersQty,
            PaymentOnCashValue,
            PaymentOnCashValueCurrency,
            PaymentMode,
          };
        });
    
        // Ordenar por fecha de confirmación y luego por orden
        const sortedData = data
          .sort((a, b) => {
            return new Date(b.LoadShipConfirmUTCDateTime) - new Date(a.LoadShipConfirmUTCDateTime);
          })
          .sort((a, b) => a.Order - b.Order);
    
        // Actualizar el estado con los datos procesados
        setLoads(sortedData);
        setDataFilterLoad(sortedData);
        setFilter("");
        setFilterTransport("");
        setFilterTransportOvTrn("");
        setLoading(false);
        setCount(count);
        setLoadsData(loads, count);
      });
    } catch (error) {
      console.error(error);
    }
  };

  
  useEffect(() => {
    if (dataOrderRunning) {
      getTMSLoad();
    }
  }, [dataOrderRunning]);
  
  useEffect(() => {
    getDistributionCenters();
    getSectors();
    getOrderRunning();
  }, []);

  return (
    <div className="containerTms">
      <div className="container-search">
        <TMSFiltersCarga isBlankActive={isBlankActive} handleClearFilters={handleClearFilters}/>
        <TMSFiltersOvTrn isBlankActive={isBlankActive} handleClearFilters={handleClearFilters}/>
        <TMSFiltersTransport isBlankActive={isBlankActive} handleClearFilters={handleClearFilters}/>
        <TMSFiltersDate isBlankActive={isBlankActive} handleClearFilters={handleClearFilters}/>
        <div className="button-filter-icon">
          <IconButton onClick={handleClearFilters} className="filter-icon">
            <FilterIcon />
          </IconButton>
        </div>
      </div>
      <div className="container-padre">
        <div className="container-carga">
          <Col
            className={`children-inner-container-with-bg ${
              showDetail
                ? "children-inner-container-with-bg-detail-visible"
                : ""
            }`}
          >
            <div
              className={`carga-list-container ${
                showDetail ? "detail-carga-visible" : ""
              }`}
            >
              <PaginatedComponent
                totalRecords={count}
                currentPage={selectedPageId}
                setCurrentPage={setSelectedPageId}
                recordsPerPage={selectedPageSize}
                setRecordsPerPage={setSelectedPageSize}
                pageData={loads}
                setPageData={setLoads}
                handleSelectLoad={handleSelectLoad}
                selecteLoadCarga={selecteLoadCarga}
                tmsLoad={getTMSLoad}
                setSelecteLoadCarga={setSelecteLoadCarga}
                setSelecteShipping={setSelecteShipping}
                setSelectedShippingId={setSelectedShippingId}
                setShowDetail={setShowDetail}
                setIsDetailExpanded={setIsDetailExpanded}
                areAllSelected={areAllSelected}
              />
            </div>
          </Col>
          {selecteLoadCarga && (
            <div className="container-shipping-list">
              <ShippingList
                carga={selecteLoadCarga}
                shipments={selecteLoadCarga.LoadLines}
                onShippingSelect={handleSelectShipping}
                handleResetShipping={handleResetShipping}
                selectShippingId={selectedShippingId}
                listShipping={listShipping}
                setListShipping={setListShipping}
              />
            </div>
          )}
          {selecteLoadCarga && (
            <div
              className={`container-action-buttons ${
                isMapSelected ? "map-selected" : ""
              }`}
            >
              <ActionButtons
                isShippingSelected={!selectedShippingId}
                isLoadSelected={!isLoadSelected}
                selecteLoad={selecteLoadCarga}
                selectedShipment={selecteShipping}
                listShipping={listShipping}
                dataOrderRunningg={dataOrderRunning.data?.ordersTable}
                dataOrderRunning={dataOrderRunning}
                distributionCenters={
                  dataDistributionCenters.data?.distributionCenters
                }
                sectors={dataSectors.data?.sectors}
                getOrderRunning={getOrderRunning}
                setListShipping={setListShipping}
                loads={loads}
                setAreAllSelected={setAreAllSelected}
              />
              {isMapSelected && (
                <div className="container-modify-location">
                  <IconButton className="modify-location-button" onClick={handleOpenMapModal}>
                  <img src={MapIcon} alt="Map Icon" />
                  </IconButton>
                  <p>Modificar</p>
                  <p>Ubicación</p>
                </div>
              )}
            </div>
          )}
          {isMapModalOpen && (
            <MapModal
              address={`${selecteShipping.Street}, ${selecteShipping.City}, ${selecteShipping.State}`}
              city={selecteShipping.City}
              state={selecteShipping.State}
              street={selecteShipping.Street}
              compliment={selecteShipping.BuildingCompliment}
              onClose={handleCloseMapModal}
              shippingId={selecteShipping.ShipmentId}
              setNewLocation={handleNewLocationUpdate}
            />
          )}
        </div>
        <div className="container-details-info-envios">
          <div
            className={`container-details ${
              showDetail ? "container-details-visible" : ""
            } ${!isDetailExpanded ? "container-details-contracted" : ""}`}
          >
            {selecteLoadCarga && (
              <DetailCarga
                loadDetails={selecteLoadCarga}
                shippingDetails={selecteShipping}
              />
            )}
          </div>
          {selecteShipping && (
            <div className="container-info-envios">
              <InfoEnvios
                lines={selecteShipping.ItemList}
                onMapTabSelect={handleMapSelect}
                tabValue={tabValue}
                setTabValue={setTabValue}
                selectedShipping={selecteShipping}
                isMapModalOpen={isMapModalOpen}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
