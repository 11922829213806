import React, { useState, useEffect } from "react";
import { Image } from "antd";
import { useLazyQuery } from "@apollo/client";
import { Grid,CircularProgress,TextField,IconButton,InputAdornment,Box,AccordionActions ,AccordionSummary, AccordionDetails,Accordion,Button } from '@mui/material';
import Search from "@mui/icons-material/Search";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import RouteCardLoad from "./RouteCard";
import OrdersCard from "./OrdersCard";
import DetailsCard from "./DetailsCard"
import OrdersCardDetails from "./DetailsOrder";
import EvidenceCard from "./Evidences"
import moment from "moment";
import "moment/locale/es";
import localStorageService from "../../../../services/localStorageService";
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DrawIcon from '@mui/icons-material/Draw';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CollectionsIcon from '@mui/icons-material/Collections';
import SpeedIcon from '@mui/icons-material/Speed';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { getColumnSearchProps, getColumnSearchPropsText } from "./UseCases";
import {
  fetchCompletedRoutes,
  dataRoutesForRouteBatch,
  fetchtelemetry,
  countRegisterRoute,
  countRegisterRouteNumber,
  CountFilterPlaniCompleteRoutes,
  FilterPlaniCompleteRoutes,
  FilterDetailsCompleteRoutes,
  fetchCompletedRoutesDetails,
  CountFilterCompleteRoutesDetails
} from "../../queries/routesBatch";
import  {DeliveryRoute}  from "./DeliveryRoute";
import "../styles/styles.css";
import * as geolib from "geolib";

export const CompletedRoutesScreenV2 = (props) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    JSON.parse(localStorageService.get("userDistributionsCenters"))
  );
  const [telemetryUserVehicle, setTelemetryUserVehicle] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [
    telemetryOrderTablestartDateTime,
    setTelemetryOrderTablestartDateTime,
  ] = useState("");
  const [telemetryOrderTableEndDateTime, setTelemetryOrderTableEndDateTime] =
    useState("");
  const [visibleTelemetry, setVisibleTelemetry] = useState(false);
  const [tableResponsive, setTableResponsive] = useState(false);
  const [searchFilterPlanificador, setSearchFilterPlanificador] = useState("");
  const [searchFilterConsecutivo, setSearchFilterConsecutivo] = useState("");
  const [searchFilterCarga, setSearchFilterCarga] = useState("");
  const [searchFilterOVTRN, setSearchFilterOVTRN] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [numeberPagination, setNumeberPagination] = useState(30);
  const [limitPagination, setlimitPagination] = useState(0);
  const [selectedRoute, setSelectedRoute] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [selectedOrderDetails,setSelectedOrderDetails] = useState([])
  const [orderNumerSelected, setOrderNumerSelected] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);
  const [expandedPanel, setExpandedPanel] = useState('panel1');
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(100);

 


  const [getCompletedRoutes, dataCompletedRoutes] = useLazyQuery(
    countRegisterRoute,
    {
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
      },

      fetchPolicy: "no-cache",
    }
  );
  const [getCompletedRoutesNumber, dataCompletedRoutesNumber] = useLazyQuery(
    countRegisterRouteNumber,
    {
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
      },

      fetchPolicy: "no-cache",
    }
  );

  const [getRoutesForRouteBatch, dataroutesForRouteBatch] = useLazyQuery(
    dataRoutesForRouteBatch,
    {
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
      },
      fetchPolicy: "no-cache",
    }
  );


  const handleChangeCompot = (panel) => (event, isExpanded) => {
    if (!isExpanded) {
      // Si el panel actual se cierra, establecer el siguiente panel como abierto
      switch (panel) {
        case 'panel1':
          setExpandedPanel('panel2');
          break;
        case 'panel2':
          setExpandedPanel('panel1');
          break;
        // Añadir más casos según sea necesario
        default:
          setExpandedPanel(null);
          break;
      }
    } else {
      // Si el panel se abre, mantenerlo abierto
      setExpandedPanel(panel);
    }
  };

  


  const [getPagineRoute] = useLazyQuery(fetchCompletedRoutes, {
    fetchPolicy: "no-cache",
  });
  const [getFilterDetailsCompleteRoutes] = useLazyQuery(FilterDetailsCompleteRoutes, {
    fetchPolicy: "no-cache",
  });
  const [getFilterCompleteRoute] = useLazyQuery(FilterPlaniCompleteRoutes, {
    fetchPolicy: "no-cache",
  });
  const [getCountCompleteRoute] = useLazyQuery(CountFilterPlaniCompleteRoutes, {
    fetchPolicy: "no-cache",
  });
  const [getCountCompleteRouteDetails] = useLazyQuery(CountFilterCompleteRoutesDetails, {
    fetchPolicy: "no-cache",
  });
  const [getFuncCompletedRoutesDetails] = useLazyQuery(fetchCompletedRoutesDetails, {
    fetchPolicy: "no-cache",
  });
  const [getTelemetry, dataTelemetry] = useLazyQuery(fetchtelemetry, {
    fetchPolicy: "no-cache",
  });
  const [polylines, setPolylines] = useState("");
  const [routesBatch, setRoutesBatch] = useState([]);
  const [filterRoutesBatch, setFilterRoutesBatch] = useState([]);
  const [filterRoutesBatchDetails, setFilterRoutesBatchDetails] = useState([]);
  const [countFilterRouteComplete, setCountFilterRouteComplete] = useState(0);
  const [countFilterRouteCompleteDetails, setCountFilterRouteCompleteDetails] = useState(0);
  const [dataFilterRoutesBatch, setDataFilterRoutesBatch] = useState([]);
  const [details, setDetails] = useState([]);
  const [filterDetails, setFilterDetails] = useState([]);
  const [selectedOrderTable, setSelectedOrderTable] = useState([]);
  const [lines, setLines] = useState("");
  const [filter, setFilter] = useState("");
  const [averageSpeed, setAverageSpeed] = useState(0);
  const [totalDistance, setTotalDistance] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [currentPageSecond, setCurrentPageSecond] = useState(1);
  const [pageSizeSecond, setPageSizeSecond] = useState(10);
  const [currentPageTree, setCurrentPageTree] = useState(1);
  const [filterdataFirst, setFilterdataFirst] = useState([]);
  const [filterdataFirstRoute, setFilterdataFirstRoute] = useState([]);
  const [pageSizeTree, setPageSizeTree] = useState(10);
  const [activeHeader, setActiveHeader] = useState(false);
  const [activeHeadeget, setActiveHeaderget] = useState(true);

  useEffect(() => {
    if (
      telemetryUserVehicle?.length > 0 &&
      telemetryOrderTablestartDateTime?.length > 0 &&
      telemetryOrderTableEndDateTime?.length > 0
    ) {
      const telemetryOrderTableStartDateTimeUTC = moment(
        moment.utc(telemetryOrderTablestartDateTime)
      )
        .add(5, "hours")
        .format("YYYY-MM-DDTHH:mm:ss+00:00");
      const telemetryOrderTableEndDateTimeUTC = moment(
        moment.utc(telemetryOrderTableEndDateTime)
      )
        .add(5, "hours")
        .format("YYYY-MM-DDTHH:mm:ss+00:00");

      getTelemetry({
        variables: {
          userVehicleId: telemetryUserVehicle,
          startDateTime: telemetryOrderTableStartDateTimeUTC,
          endDateTime: telemetryOrderTableEndDateTimeUTC,
        },
      });
    }
  }, [
    telemetryUserVehicle,
    telemetryOrderTablestartDateTime,
    telemetryOrderTableEndDateTime,
  ]);

  const onSelectRoute = (route) => {
    setSelectedRoute(route);
  };

  const onSelectDetails = (details) => {
      setSelectedDetails(details);
    };

  useEffect(() => {
    if (dataTelemetry.data?.telemetry?.length > 0) {
      const distanceSum = dataTelemetry.data?.telemetry.reduce(
        (acc, curr) => acc + curr.distance,
        0
      );
      setTotalDistance(distanceSum);

      const speedSum = dataTelemetry.data?.telemetry.reduce(
        (acc, curr) => acc + curr.speed,
        0
      );
      const speedAverage = speedSum / dataTelemetry.data?.telemetry.length;
      setAverageSpeed(Math.round(speedAverage));

      setVisibleTelemetry(true);
    } else {
      setVisibleTelemetry(false);
    }
  }, [dataTelemetry.data, dataTelemetry.loading]);

  useEffect(() => {
    const warehouses = JSON.parse(
      localStorageService.get("selectedWarehouses")
    )?.warehouses;
    setSelectedWarehouse(warehouses);
  }, [props.flag]);

  useEffect(() => {
    getCompletedRoutes();
    getRoutesForRouteBatch();
    getCompletedRoutesNumber();
  }, []);

  useEffect(() => {
    setFilter(
      localStorage.getItem("searchTarget") &&
        localStorage.getItem("searchTarget").length > 0
        ? localStorage.getItem("searchTarget")
        : ""
    );
    if (localStorage.getItem("searchTarget").length === 0) {
      setFilterdataFirstRoute([]);
      setDataFilterRoutesBatch([]);
      setTelemetryUserVehicle([]);
    }
  }, [props.flag]);

  //console.log(pagineRoute.data?.routes)
  useEffect(() => {
    if (
      dataroutesForRouteBatch &&
      dataroutesForRouteBatch.data &&
      dataroutesForRouteBatch.data?.routes.length > 0
    ) {
      setFilterdataFirst(dataroutesForRouteBatch.data?.routes);
    }
  }, [dataroutesForRouteBatch, dataroutesForRouteBatch.data]);

  useEffect(() => {
    completeRoutesPagination(numeberPagination, limitPagination);
  }, []);

  const completeRoutesPagination = async (limit, offset) => {
    setLoading(true);
    setLoadingData(true)
    const responseOrdeNumberEdit = await getPagineRoute({
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
        limit: limit,
        offset: offset * limit,
      },
    });

    if (
      responseOrdeNumberEdit &&
      responseOrdeNumberEdit.data.routesBatch.length > 0
    ) {
      const newRoutesBatch = responseOrdeNumberEdit.data.routesBatch.map(
        (item) => {
          const {
            id,
            batch,
            createdAt,
            startDateRoute,
            endDateRoute,
            routes,
            licencePlate,
            user,
          } = item;

          return {
            id,
            batch,
            createdAt,
            startDateRoute,
            endDateRoute,
            routes,
            driverName: user.displayName,
            planner: routes[0]?.createByUser?.displayName,
            licencePlate,
          };
        }
      );
      setRoutesBatch(newRoutesBatch);
      setLoading(false);
      setLoadingData(false);
      setFilterRoutesBatch();
      setFilterDetails();
      
      
    }
  };

/*
  const headerColumns = [
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "Creación",
      className: "table-column",
      align: "center",
      render: (value) => {
        let date = moment.utc(value);
        let localTime = moment(date).local().format("DD/MM/YYYY h:mm a");

        return <span>{localTime}</span>;
      },
      ...getColumnSearchProps("createdAt"),
    },
    {
      key: "planner",
      dataIndex: "planner",
      title: "Planificador",
      className: "table-column",
      align: "center",
      render: (value) => {
        return <span>{value}</span>;
      },
      ...getColumnSearchPropsText("Planificador", "displayName"),
    },
    {
      key: "licencePlate",
      dataIndex: "licencePlate",
      className: "table-column",
      title: "Placa",
      render: (value) => {
        return <span>{value}</span>;
      },
      ...getColumnSearchPropsText("Placa", "licencePlate"),
    },
    {
      key: "driverName",
      dataIndex: "driverName",
      className: "table-column",
      title: "Conductor",
      render: (value) => {
        return <span>{value}</span>;
      },
      ...getColumnSearchPropsText("Conductor", "driverName"),
    },
    {
      key: "startDateRoute",
      dataIndex: "startDateRoute",
      title: "Inicio de Ruta",
      className: "table-column",
      align: "center",
      render: (value) => {
        let date = moment.utc(value);
        let localTime = moment(date).format("DD/MM/YYYY h:mm a");

        return <span>{localTime}</span>;
      },
      ...getColumnSearchProps("startDateRoute"),
    },
    {
      key: "endDateRoute",
      dataIndex: "endDateRoute",
      title: "Fin de Ruta",
      className: "table-column",
      align: "center",
      render: (value) => {
        let date = moment.utc(value);
        let localTime = moment(date).format("DD/MM/YYYY h:mm a");

        return <span>{localTime}</span>;
      },
      ...getColumnSearchProps("endDateRoute"),
    },
  ];
  

  const detailsColumns = [
    {
      key: "orderNumber",
      dataIndex: "orderTable",
      className: "table-column",
      title: "Orden",
      render: (record) => {
        return <span>{record?.orderNumber}</span>;
      },
      ...getColumnSearchPropsText("Orden", "orderTable.orderNumber"),
    },
    {
      key: "type",
      dataIndex: "orderTable",
      title: "Tipo",
      className: "table-column",
      align: "center",
      render: (record) => {
        return (
          <span>
            {record?.type === "Envío" || record?.type === "delivery"
              ? "Envío"
              : record?.type === "pickup"
              ? "Recogida"
              : record?.type === "customService"
              ? "Servicio Personalizado"
              : record?.type === "warehousesTransfer"
              ? "Traslado entre Bodegas"
              : ""}
          </span>
        );
      },
    },
    {
      key: "custFullName",
      dataIndex: "orderTable",
      className: "table-column",
      title: "Cliente",
      render: (record) => {
        return <span>{record?.custFullName}</span>;
      },
    },
    {
      key: "status",
      dataIndex: "status",
      title: "Estado",
      className: "table-column",
      render: (value) => {
        let status = "";
        switch (value) {
          case "none":
            status = "Ninguno";
            break;
          case "start":
            status = "Ninguno";
            break;
          case "started":
            status = "En reparto";
            break;
          case "delivered":
            status = "Completamente Entregado";
            break;
          case "undelivered":
            status = "Devuelto";
            break;
          case "partial_delivered":
            status = "Con Novedad";
            break;
          case "rescheduled_delivery":
            status = "Entrega Reprogramada";
            break;
          default:
            status = "Ninguno";
            break;
        }

        return status;
      },
    },
    {
      key: "description",
      dataIndex: "description",
      title: "Motivo",
      className: "table-column",
      render: (_, record) => {
        return <span>{record?.reason?.description}</span>;
      },
    },
    {
      key: "consecutiveBurden",
      dataIndex: "orderTable",
      title: "Carga",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveBurden}</span>;
      },
    },
    {
      key: "consecutiveBill",
      dataIndex: "orderTable",
      title: "Factura",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveBill}</span>;
      },
    },
    {
      key: "consecutiveRemission",
      dataIndex: "orderTable",
      title: "Remisión",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveRemission}</span>;
      },
    },
    {
      key: "consecutiveSaleOrder",
      dataIndex: "orderTable",
      title: "Orden/TRN",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveSaleOrder}</span>;
      },
    },
    {
      key: "receivedPerson",
      dataIndex: "receivedPerson",
      title: "Nombre",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      key: "receivedDocument",
      dataIndex: "receivedDocument",
      title: "Documento",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      key: "notes",
      dataIndex: "orderTable",
      className: "table-column",
      title: "Notas",
      render: (record) => {
        return <span>{record?.notes}</span>;
      },
    },
    {
      key: "paymentMethod",
      dataIndex: "orderTable",
      title: "Metodo de Pago",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.paymentMethod}</span>;
      },
    },
    {
      key: "state",
      dataIndex: "orderTable",
      title: "Departamento",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.state}</span>;
      },
    },
    {
      key: "city",
      dataIndex: "orderTable",
      title: "Ciudad",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.city}</span>;
      },
    },
    {
      key: "address",
      dataIndex: "orderTable",
      title: "Dirección",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.address}</span>;
      },
    },
    {
      key: "distanceText",
      dataIndex: "distanceText",
      title: "Distancia",
      className: "table-column",
      align: "center",
    },
    {
      key: "durationText",
      dataIndex: "durationText",
      title: "Duración",
      className: "table-column",
    },
    {
      key: "expectedDeliveryStartTime",
      dataIndex: "orderTable",
      title: "Fecha inicio compromiso de entrega ",
      className: "table-column",
      align: "center",
      render: (record) => {
        let date = moment.utc(record?.expectedDeliveryStartTime);
        let localTime = moment(date).format("DD/MM/YYYY h:mm a");

        return (
          <span>{localTime !== "Fecha inválida" ? localTime : "N/D"}</span>
        );
      },
    },
    {
      key: "expectedDeliveryEndTime",
      dataIndex: "orderTable",
      title: "Fecha fin compromiso de entrega",
      className: "table-column",
      align: "center",
      render: (record) => {
        let date = moment.utc(record?.expectedDeliveryEndTime);
        let localTime = moment(date).format("DD/MM/YYYY h:mm a");

        return (
          <span>{localTime !== "Fecha inválida" ? localTime : "N/D"}</span>
        );
      },
    },
    {
      key: "startDateTime",
      dataIndex: "startDateTime",
      title: "Inicio",
      className: "table-column",
      align: "center",
      render: (value) => {
        let date = moment.utc(value);
        let localTime = moment(date).format("DD/MM/YYYY h:mm a");

        return <span>{localTime}</span>;
      },
    },
    {
      key: "endDateTime",
      dataIndex: "endDateTime",
      title: "Fin",
      className: "table-column",
      align: "center",
      render: (value) => {
        let date = moment.utc(value);
        let localTime = moment(date).format("DD/MM/YYYY h:mm a");

        return <span>{localTime}</span>;
      },
    },
    {
      key: "consecutiveShipping",
      dataIndex: "orderTable",
      title: "Envío",
      className: "table-column",
      align: "center",
      render: (record) => {
        return <span>{record?.consecutiveShipping}</span>;
      },
    },
  ];

  const linesColumns = [
    {
      key: "productNumber",
      dataIndex: "productNumber",
      title: "Código",
      className: "table-column",
      align: "center",
      className: "table-column",
    },
    {
      key: "productName",
      dataIndex: "productName",
      className: "table-column",
      title: "Nombre",
    },
    {
      key: "initialQuantity",
      dataIndex: "initialQuantity",
      title: "Cantidad",
      className: "table-column",
      align: "right",
    },
    {
      key: "summationQuantity",
      dataIndex: "summationQuantity",
      title: "Entregado",
      className: "table-column",
      align: "right",
    },
  ];
  */
  
  let detilsLength;

  const handleSelectRoute = (selectedRoute) => {
    //console.log("Ruta seleccionada:", selectedRoute);
   
      onSelectRoute(selectedRoute);
      setDetails(selectedRoute.routes);
      setSearchFilterConsecutivo();
      detilsLength = selectedRoute.routes.length;
      FilterRouteBathcDetails(selectedRoute?.routes);
      setExpandedPanel('panel1');
      setTelemetryUserVehicle(
      selectedRoute.routes[0]?.user?.userVehicle?.id
      
    );
    setActiveHeader(true);
    //console.log(selectedRoute.routes) // Este es el cambio para manejar la selección del Box en lugar del botón
  };
/*
  const rowSelectionHeader = {
    
    onChange: (selectedRowKeys, selectedRows) => {
      setDetails(selectedRows[0].routes);
      setSearchFilterConsecutivo();
      detilsLength = selectedRows[0].routes.length;
      FilterRouteBathcDetails(selectedRows[0]?.routes);
      setTelemetryUserVehicle(
        selectedRows[0]?.routes[0]?.user?.userVehicle?.id
      );
      setActiveHeader(true);
      //console.log(selectedRows[0].routes)
    },
    
  };
*/
  let linesLength;

  

  const handleSelectDetails = (selectedDetails) => {
    
    
    const records = [];
    selectedDetails?.orderTable?.ordersLines.map((item) => {
      const {
        id,
        productNumber,
        productName,
        initialQuantity,
        summationQuantity,
      } = item;
  
      return records.push({
        id,
        productNumber,
        productName,
        initialQuantity,
        summationQuantity,
      });
    });
    //console.log(searchFilterConsecutivo, searchFilterCarga, searchFilterOVTRN)
    if(searchFilterConsecutivo?.length > 1 || searchFilterCarga?.length > 1 || searchFilterOVTRN?.length > 1){
      funcCompletedRoutesDetails(selectedDetails, 30, 0);
    }
    setOrderNumerSelected(selectedDetails?.orderTable?.orderNumber)
    setLines(records);
  
   
    linesLength = records.length;
    setSelectedOrderTable(selectedDetails);
  
    setPolylines(selectedDetails?.polylines);
    setTelemetryOrderTablestartDateTime(selectedDetails?.startDateTime);
    setTelemetryOrderTableEndDateTime(selectedDetails?.endDateTime);
    setTableResponsive(true);
    
    if (activeHeader === false || filter.length > 0) {
      FilterRouteBathc(
        selectedDetails?.batch,
        selectedDetails?.user?.userVehicle?.id
      );
    }

   
  };

  
  /*
  const rowSelectionDetails = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRows)
      const records = [];
      selectedRows[0].orderTable.ordersLines.map((item) => {
        const {
          id,
          productNumber,
          productName,
          initialQuantity,
          summationQuantity,
        } = item;

        return records.push({
          id,
          productNumber,
          productName,
          initialQuantity,
          summationQuantity,
        });
      });
      //console.log(searchFilterConsecutivo, searchFilterCarga, searchFilterOVTRN)
      if(searchFilterConsecutivo?.length > 1 || searchFilterCarga?.length > 1 || searchFilterOVTRN?.length > 1){
        funcCompletedRoutesDetails(selectedRows[0], 30, 0);
      }
      setLines(records);
      linesLength = records.length;
      setSelectedOrderTable(selectedRows[0].orderTable);

      setPolylines(selectedRows[0].polylines);
      setTelemetryOrderTablestartDateTime(selectedRows[0].startDateTime);
      setTelemetryOrderTableEndDateTime(selectedRows[0].endDateTime);
      setTableResponsive(true);
      if (activeHeader === false || filter.length > 0) {
        FilterRouteBathc(
          selectedRows[0].batch,
          selectedRows[0]?.user?.userVehicle?.id
        );
      }
    },
  };
  */

  const handleImageClick = (imageURL) => {
    setSelectedImage(imageURL);
   };
 
  const handleCloseViewer = () => {
    setSelectedImage(null);
   };

  const handleCLickZoomIn = () =>{
    {setZoomLevel(zoomLevel + 10)}
  }
  const handleCLickZoomOut = () =>{
    {setZoomLevel(zoomLevel - 10)}
  }

  const handlePageChange = (page, pageSize) => {
   // console.log(filterRoutesBatch)
    if(filterRoutesBatch?.length > 0){
      handlsavePlanificador(searchFilterPlanificador,pageSize, page)
      setFilterRoutesBatch();
    }else if(routesBatch?.length > 0){
      completeRoutesPagination(pageSize, page);
      setRoutesBatch();
    }
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handlePageChangeSecond = (page, pageSize) => {
    setCurrentPageSecond(page);
    setPageSizeSecond(pageSize);
  };
  const handlePageChangeTree = (page, pageSize) => {
    setCurrentPageTree(page);
    setPageSizeTree(pageSize);
  };

  let filteredDataRouteBatch;
  const FilterRouteBathc = (dataBatch, dataTelemetry) => {
    if (filter.length > 0) {
      filteredDataRouteBatch = routesBatch.filter(
        (item) => item.batch === dataBatch
      );
      setDataFilterRoutesBatch(filteredDataRouteBatch);
      setActiveHeaderget(false);
      setTelemetryUserVehicle(dataTelemetry);
    } else {
      setDataFilterRoutesBatch(routesBatch);
      setTelemetryUserVehicle(dataTelemetry);
    }
  };
  const FilterRouteBathcDetails = (dataRoute) => {
    let _dataRoute = [];

    for (let i = 0; i < dataRoute?.length; i++) {
      const data = dataRoute[i];

      for (let j = 0; j < routesBatch?.length; j++) {
        const batch = routesBatch[j];

        for (let k = 0; k < batch?.routes?.length; k++) {
          const route = batch.routes[k];

          if (route.id === data.id) {
            _dataRoute.push(route);
          }
        }
      }
    }

    //console.log(_dataRoute);
    setFilterdataFirstRoute(_dataRoute);
  };

  //console.log(dataTelemetry.data?.telemetry)
  const calcularDistanciaRecorrida = () => {
    if (
      !dataTelemetry.data?.telemetry ||
      dataTelemetry.data.telemetry.length === 0
    ) {
      return 0; // Devuelve 0 si no hay suficientes datos
    }
  
    let totalDistance = 0;
    let lastData = dataTelemetry.data.telemetry[0];
    for (let i = 1; i < dataTelemetry.data.telemetry.length; i++) {
      const currentData = dataTelemetry.data.telemetry[i];
      const distance = geolib.getDistance(
        { latitude: lastData.latitude, longitude: lastData.longitude },
        { latitude: currentData.latitude, longitude: currentData.longitude }
      );
      if (distance > 10) {
        totalDistance += distance;
      }
      lastData = currentData;
    }
    return totalDistance;
  };
  
  const calcularVelocidadPromedio = () => {
    if (
      !dataTelemetry.data?.telemetry ||
      dataTelemetry.data.telemetry.length === 0
    ) {
      return 0; // Devuelve 0 si no hay suficientes datos
    }
  
    let totalSpeed = 0;
    let count = 0;
    let lastData = dataTelemetry.data.telemetry[0];
    for (let i = 1; i < dataTelemetry.data.telemetry.length; i++) {
      const currentData = dataTelemetry.data.telemetry[i];
      const distance = geolib.getDistance(
        { latitude: lastData.latitude, longitude: lastData.longitude },
        { latitude: currentData.latitude, longitude: currentData.longitude }
      );
      if (distance > 10) {
        const time = moment(currentData.timestamp).diff(
          moment(lastData.timestamp),
          "seconds"
        );
        if (time > 0) {
          const speed = (distance / time) * 3.6; // Convertir de m/s a km/h
          totalSpeed += speed;
          count++;
        }
      }
      lastData = currentData;
    }
    const averageSpeed = count > 0 ? totalSpeed / count : 0;
    return averageSpeed.toFixed(2);
  };
  

  const textFieldEventPlanificador = (ev) => {
    const inputValuePlanificador = ev.target.value;
    setSearchFilterPlanificador(inputValuePlanificador);
    setExpandedPanel('panel1');
  };
  const textFieldEventConsecutivo = (ev) => {
    const inputValueConsecutivo = ev.target.value; 
    setSearchFilterConsecutivo(inputValueConsecutivo);
    setExpandedPanel('panel1');
  };
  const textFieldEventCarga = (ev) => {
    const inputValueCarga = ev.target.value;
    setSearchFilterCarga(inputValueCarga);
    setExpandedPanel('panel1');
  };
  const textFieldEventOVTRN = (ev) => {
    const inputValueOVTRN = ev.target.value;
    setSearchFilterOVTRN(inputValueOVTRN);
    setExpandedPanel('panel1');
  };

  let countFilterCompleteRouteDetails;
  const funcCompletedRoutesDetails = async (search, limit, offset) =>{
    setLoading(true);
    setLoadingData(true);
    const responseFilterCompleteRouteDetails = await getFuncCompletedRoutesDetails({
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
        batch: search.batch,
        limit: limit,
        offset: offset * limit,
      },
    });
    if (
      responseFilterCompleteRouteDetails &&
      responseFilterCompleteRouteDetails.data?.routesBatch?.length > 0
    ) {
      countFilterCompleteRouteDetails = await getCountCompleteRouteDetails({
        variables: {
          distributionCenterId:
            selectedWarehouse?.length > 0
              ? selectedWarehouse
              : ["00000000-0000-0000-0000-000000000000"],
            batch: search.batch
        },
      });
      setCountFilterRouteCompleteDetails(countFilterCompleteRouteDetails?.data?.routesBatch_aggregate?.aggregate?.count);
      const filterRoutesBatchDetails = responseFilterCompleteRouteDetails.data.routesBatch.map(
        (item) => {
          const {
            id,
            batch,
            createdAt,
            startDateRoute,
            endDateRoute,
            routes,
            licencePlate,
            user,
          } = item;

          return {
            id,
            batch,
            createdAt,
            startDateRoute,
            endDateRoute,
            routes,
            driverName: user.displayName,
            planner: routes[0]?.createByUser?.displayName,
            licencePlate,
          };
        }
      );
      setFilterRoutesBatchDetails(filterRoutesBatchDetails);
      setLoading(false);
      setLoadingData(false);
      setFilterRoutesBatch()
      setRoutesBatch();
    }
  };
  let countFilterCompleteRoute;
  const handlsavePlanificador = async(search, limit, offset) => {
    if(searchFilterPlanificador.length > 0){
      setLoading(true);
    
    const responseFilterCompleteRoute = await getFilterCompleteRoute({
      variables: {
        distributionCenterId:
          selectedWarehouse?.length > 0
            ? selectedWarehouse
            : ["00000000-0000-0000-0000-000000000000"],
        displayName: search,
        limit: limit,
        offset: offset * limit,
      },
    });
    
    if (
      responseFilterCompleteRoute &&
      responseFilterCompleteRoute.data?.routesBatch?.length > 0
    ) {
      countFilterCompleteRoute = await getCountCompleteRoute({
        variables: {
          distributionCenterId:
            selectedWarehouse?.length > 0
              ? selectedWarehouse
              : ["00000000-0000-0000-0000-000000000000"],
          displayName: search
        },
      });
      setCountFilterRouteComplete(countFilterCompleteRoute?.data?.routesBatch_aggregate?.aggregate?.count);
      const filterRoutesBatch = responseFilterCompleteRoute.data.routesBatch.map(
        (item) => {
          const {
            id,
            batch,
            createdAt,
            startDateRoute,
            endDateRoute,
            routes,
            licencePlate,
            user,
          } = item;

          return {
            id,
            batch,
            createdAt,
            startDateRoute,
            endDateRoute,
            routes,
            driverName: user.displayName,
            planner: routes[0]?.createByUser?.displayName,
            licencePlate,
          };
        }
      );
      setFilterRoutesBatch(filterRoutesBatch);
      setLoading(false);
      setRoutesBatch();
      setFilterRoutesBatchDetails()
    }
    }else{
      completeRoutesPagination(30,0)
    }
  };
  const handlsaveConsecutivo = async(search , limit, offset) => {
    if(searchFilterConsecutivo.length > 0){
      const responseFilterDetailsCompleteRoutes = await getFilterDetailsCompleteRoutes({
        variables: {
          distributionCenterId:
            selectedWarehouse?.length > 0
              ? selectedWarehouse
              : ["00000000-0000-0000-0000-000000000000"],
          orderNumber: search,
          consecutiveBurden: "",
          consecutiveSaleOrder: "",
          limit: limit,
          offset: offset * limit,
        },
      });
      setFilterDetails(responseFilterDetailsCompleteRoutes.data?.routesBatch[0]?.routes);
      //console.log(responseFilterDetailsCompleteRoutes.data?.routesBatch[0]?.routes);
      setDetails();
    }else{
      completeRoutesPagination(30,0)
      setSelectedOrderTable();
      setTableResponsive(false);
      setVisibleTelemetry(false);
    }
  };
  const handlsaveCarga = async (search, limit, offset) => {
    if(searchFilterCarga.length > 0){
      const responseFilterDetailsCompleteRoutes = await getFilterDetailsCompleteRoutes({
        variables: {
          distributionCenterId:
            selectedWarehouse?.length > 0
              ? selectedWarehouse
              : ["00000000-0000-0000-0000-000000000000"],
          orderNumber: "",
          consecutiveBurden: search,
          consecutiveSaleOrder: "",
          limit: limit,
          offset: offset * limit,
        },
      });
      setFilterDetails(responseFilterDetailsCompleteRoutes.data?.routesBatch[0]?.routes)
      setDetails();
    }else{
      completeRoutesPagination(30,0)
      setSelectedOrderTable();
      setTableResponsive(false);
      setVisibleTelemetry(false);
    }
  };
  const handlsaveOVTRN = async(search, limit, offset) => {
    if(searchFilterOVTRN.length > 0){
      const responseFilterDetailsCompleteRoutes = await getFilterDetailsCompleteRoutes({
        variables: {
          distributionCenterId:
            selectedWarehouse?.length > 0
              ? selectedWarehouse
              : ["00000000-0000-0000-0000-000000000000"],
          orderNumber: "",
          consecutiveBurden: "",
          consecutiveSaleOrder: search,
          limit: limit,
          offset: offset * limit,
        },
      });
      setFilterDetails(responseFilterDetailsCompleteRoutes.data?.routesBatch[0]?.routes)
      setDetails();
    }else{
      completeRoutesPagination(30,0)
      setSelectedOrderTable();
      setTableResponsive(false);
      setVisibleTelemetry(false);
    }
   
  };


  return (

<Box className="children-inner-container-with-bg-completedRoute" xs={4} sm={16} xl={16} style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
  <Box>
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <div className="SearchRoute">
          <TextField
            onChange={textFieldEventPlanificador}
            size="small"
            label="Buscar Planificador"
            color="error"
            style={{ width: "100%" }}
            value={searchFilterPlanificador?.length > 0 ? searchFilterPlanificador : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    {loading ? (
                      <CircularProgress size={10} color="error" />
                    ) : (
                      <Search onClick={() => handlsavePlanificador(searchFilterPlanificador, 30, 0)} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className="SearchRoute">
          <TextField
            onChange={textFieldEventConsecutivo}
            size="small"
            label="Buscar Consecutivo UM"
            color="error"
            style={{ width: "100%" }}
            value={searchFilterConsecutivo}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    {loading ? (
                      <CircularProgress size={10} color="error" />
                    ) : (
                      <Search onClick={() => handlsaveConsecutivo(searchFilterConsecutivo, 30,0)} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className="SearchRoute">
          <TextField
            onChange={textFieldEventCarga}
            size="small"
            label="Buscar Carga"
            color="error"
            style={{ width: "100%" }}
            value={searchFilterCarga}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    {loading ? (
                      <CircularProgress size={10} color="error" />
                    ) : (
                      <Search onClick={() => handlsaveCarga(searchFilterCarga, 30,0)} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className="SearchRoute">
          <TextField
            onChange={textFieldEventOVTRN}
            size="small"
            label="Buscar OV/TRN"
            color="error"
            style={{ width: "100%" }}
            value={searchFilterOVTRN}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    {loading ? (
                      <CircularProgress size={10} color="error" />
                    ) : (
                      <Search onClick={() => handlsaveOVTRN(searchFilterOVTRN, 30, 0)} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>
    </Grid>
  </Box>
  {loadingData ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '1' }}>
      <CircularProgress />
    </Box>
  ) : (
    <Box className="parent-container" xs={4} sm={16} xl={16} style={{ flex: '1', overflowY: 'auto' }}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Box>
            <div className="children-inner-container-target-with-bg-back">
              <RouteCardLoad
                routesBatch={routesBatch?.length > 0 ? routesBatch : filterRoutesBatch?.length > 0 ? filterRoutesBatch : filterRoutesBatchDetails}
                onSelectRoute={handleSelectRoute} 
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Accordion
          className="children-inner-container-target-with-bg-order"
          expanded={expandedPanel === "panel1"}
          onChange={handleChangeCompot('panel1')}
          style={{
            border: expandedPanel !== 'panel1' ? "2px solid #D10505" : "2px solid transparent",
            maxWidth: '90vw', // Ancho máximo del Accordion
            width: '100%', // Ancho del Accordion
            maxHeight: '50vh', // Altura máxima del Accordion
            height: 'auto', // Altura automática del Accordion
          }}
          >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <p className="title-labels-screen">
              {expandedPanel === 'panel1' ? "Listado de Ordenes:" : "Orden seleccionada:"} {expandedPanel === 'panel1' && orderNumerSelected !== undefined && orderNumerSelected !== "" && orderNumerSelected !== null ? "" : orderNumerSelected}
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <OrdersCard
                dailsOrder={details?.length > 0 ? details : filterDetails}
                onSelectDetails={handleSelectDetails} 
              />
            </Box>
          </AccordionDetails>
          </Accordion>
          <Box xs={4} sm={16} xl={16} style={{marginTop: '20px'}}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box className={`children-inner-container-target-with-bg-map ${expandedPanel === 'panel2' ? 'close' : ''}`}>
                    <DeliveryRoute
                      dataTelemetry={dataTelemetry}
                      visibleTelemetry={visibleTelemetry}
                      polylines={polylines}
                    />
                    <div className="card-telemetry">
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                      <span className="drive-details-label ord-titule">Velocidad promedio:</span>
                      <span><SpeedIcon style={{ color: "D10505", fontSize: '1em', marginRight: '0.2em' }}/>{calcularVelocidadPromedio()} Km/h</span>
                      </Grid>
                      <Grid item xs={12}>
                      <span className="drive-details-label ord-titule">Distancia recorrida:</span>
                      <span> <TravelExploreIcon style={{ color: "D10505", fontSize: '1em', marginRight: '0.2em' }}/>{calcularDistanciaRecorrida()} Mts</span>
                      </Grid>
                    </Grid>
                    </div>
                  </Box>
                </Grid>
                  <Grid item xs={12}>
                    <Box xs={4} sm={16} xl={16} className="children-inner-container-target-with-bg-order-details-cointainer" expanded={expandedPanel === 'panel2'} style={{ visibility: expandedPanel !== 'panel2' ? 'hidden' : 'visible' }}>
                      <p className="title-labels-screen">Detalles de la orden</p>
                      <OrdersCardDetails dailsOrder={selectedOrderTable} style={{ visibility: expandedPanel !== 'panel2' ? 'hidden' : 'visible' }} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={16} xl={16} style={{marginRight:"2px"}}>
                    <Accordion className="children-inner-container-target-with-bg-products" expanded={expandedPanel === 'panel2'} onChange={handleChangeCompot('panel2')}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                        <p className="title-labels-screen">Listado de Productos</p>
                      </AccordionSummary>
                      <AccordionDetails style={{ overflowY: "auto", maxHeight: "20vh", padding:"5px" }}>
                        <DetailsCard lines={lines} />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="childrene-evidence">
                      <div>
                        <p className="title-labels-screen">Evidencias</p>
                      </div>
                      <div style={{ overflowX: 'auto', width: '100%' }}>
                        <Stack
                          direction={{ xs: 'row', sm: 'row' }} 
                          spacing={{ xs: 1, sm: 2, md: 4 }}
                          sx={{ minWidth: '100%', display: 'inline-flex' }} 
                        >
                          {selectedOrderTable?.orderTable?.evidences?.map((selectedOrder, index) => (
                            <Card key={`container-${index}`} style={{ minWidth: '150px', height: '95%' }}>
                              <CardActionArea onClick={() => handleImageClick(selectedOrder?.evidenceURL)}>
                                <div style={{ width: '100%', height: '95%' }}> 
                                  <Image
                                    src={selectedOrder?.evidenceURL}
                                    alt={`Evidencia ${index + 1}`}
                                    style={{ width: '150px', height: '150px', objectFit: 'cover' }} 
                                  />
                                </div>
                                <CardContent>
                                  <Typography className="title-labels-screen" component="div">
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                      {selectedOrder?.evidenceType === 'FIRMA' && (
                                        <DrawIcon style={{ color: "D10505", fontSize: '1em', marginRight: '0.2em' }} />
                                      )}
                                      {selectedOrder?.evidenceType === 'FOTO' && (
                                        <CameraAltIcon style={{ color: "D10505", fontSize: '1em', marginRight: '0.2em' }} />
                                      )}
                                      {selectedOrder?.evidenceType === 'GALERÍA' && (
                                        <CollectionsIcon style={{ color: "D10505", fontSize: '1em', marginRight: '0.2em' }} />
                                      )}
                                      {selectedOrder?.evidenceType}
                                    </span>
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          ))}
                        </Stack>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )}
</Box>
)};
