import React from "react";
import { Grid, Avatar } from '@mui/material';
import moment from "moment";

const OrdersCardDetails = ({ dailsOrder }) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Avatar style={{ backgroundColor: "#D10505" }} size="large">
                                {dailsOrder?.orderTable?.custFullName?.charAt(0)}
                            </Avatar>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div>
                            <span className="drive-details-label">Cliente:</span><br/>
                            <span className="drive-details-value planificator">{dailsOrder?.orderTable?.custFullName}</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                    <span className="drive-details-label">Dirección:</span><br/>
                    <span className="drive-details-value planificator">{dailsOrder?.orderTable?.address}</span>
                </div>
            </Grid>
            <Grid item xs={2}>
                <div>
                    <span className="drive-details-label">Fecha incio:</span><br/>
                    <span className="drive-details-value">
                        {moment(dailsOrder?.orderTable?.expectedDeliveryStartTime).format("DD/MM/YYYY")}
                    </span>
                    <br />
                    <span className="drive-details-value">
                        {moment(dailsOrder?.orderTable?.expectedDeliveryStartTime).format("HH:mm:ss")}
                    </span>
                </div>
            </Grid>
            <Grid item xs={2}>
                <div>
                    <span className="drive-details-label">Fecha Fin:</span><br/>
                    <span className="drive-details-value">
                        {moment(dailsOrder?.orderTable?.expectedDeliveryEndTime).format("DD/MM/YYYY")}
                    </span>
                    <br />
                    <span className="drive-details-value">
                        {moment(dailsOrder?.orderTable?.expectedDeliveryEndTime).format("HH:mm:ss")}
                    </span>
                </div>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <div>
                            <span className="drive-details-label">Nombre:</span><br/>
                            <span className="drive-details-value">{dailsOrder?.receivedPerson}</span>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <span className="drive-details-label">Documento:</span><br/>
                            <span className="drive-details-value">{dailsOrder?.receivedDocument}</span>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <span className="drive-details-label">Albaran:</span><br/>
                            <span className="drive-details-value">{dailsOrder?.orderTable?.consecutiveRemission}</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid> 
            <div className="half-circle"></div>
        </Grid>
    );
}

export default OrdersCardDetails;
