import React, { useState, useEffect } from "react";
import { Avatar, Timeline, Row, Col } from "antd";
import moment from "moment";
import imageStatus from "../../../../images/turbo1.png";
import { CarOutlined , CheckCircleTwoTone} from '@ant-design/icons';
import { Padding } from "@mui/icons-material";
import { Grid, Divider, TextField, IconButton, InputAdornment, Box, Card} from '@mui/material';

const EvidenceCard = ({ evidencesLine }) => {

    //console.log(evidencesLine.evidences[0].evidenceURL);
    
      console.log(evidencesLine?.evidences[0]?.evidenceURL)

    
    return (
    <div className="orders-card-container-lines">

    {evidencesLine?.length > 0 &&
        evidencesLine.map((evidencesLine, index) => (
            <div key={index}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
              <Box> 
                <img
                  src={"https://navstorageapps.blob.core.windows.net/nav-cooltrack-evidences/image-1711034395689.jpg"}
                  alt="Descripción de la imagen"
                />
              </Box>
            </Grid>
            </Grid>
                
        </div>
        ))}
       
        </div>

    );
}

export default EvidenceCard;

