import React, { useState, useEffect } from "react";
import { Avatar, Timeline, Row, Col } from "antd";
import moment from "moment";
import imageStatus from "../../../../images/turbo1.png";
import { CarOutlined , CheckCircleTwoTone} from '@ant-design/icons';
import { Padding } from "@mui/icons-material";
import { Grid, Divider, TextField, IconButton, InputAdornment, Box, Card } from '@mui/material';

const DetailsCard = ({ lines }) => {

    //console.log(lines);
    return (
    <div className="orders-card-container-lines" >

    {lines?.length > 0 &&
        lines.map((lines, index) => (
          <div key={index} className="children-inner-container-target-with-bg-order-producst"   style={{marginBottom: "10px",padding: "15px"}}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
              <Box>
                <span className="drive-details-label ord-titule">Producto</span>
                <span className="drive-details-value car-value">{lines?.productName}</span>
                <span className="drive-details-label ord-titule">Código</span>
                <span className="drive-details-value car-value">{lines?.productNumber}</span>
               
              </Box>
              </Grid>
              <Grid item xs={4}>
              <Box >
                <span className="drive-details-label ord-titule">Cantidad Inicial</span>
                <span className="drive-details-value car-value">{lines?.initialQuantity}</span>
                <span className="drive-details-label ord-titule">Entregado</span>
                <span className="drive-details-value car-value">{lines?.summationQuantity}</span>
             
              </Box>

              </Grid>
            </Grid>
                
        </div>
        ))}
       
        </div>

    );
}

export default DetailsCard;

